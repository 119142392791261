import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Inicio from '../pages/Inicio';
import NotFound from '../pages/NotFound';
import LayoutPublic from '../layouts/LayoutPublic';
import Consultora from '../pages/Consultora';
import Plataforma from '../pages/Plataforma';
import Programas from '../pages/Programas';
import Construccion from '../pages/Construccion';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <LayoutPublic />,
    errorElement: <NotFound />,
    children: [
      {
        index: 'true',
        element: <Inicio />,
      },
      {
        path: '/consultora',
        element: <Consultora />,
      },
      {
        path: '/programas',
        element: <Programas />,
      },
      {
        path: '/plataforma',
        element: <Plataforma />,
      },
    ],
  },
  // {
  //     path: '',
  //     element: ,
  // },
]);
