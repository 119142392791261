import React from 'react';
import imgUno from '../assets/images/Imagen_4.png';
import imgDos from '../assets/images/Imagen_5.png';
import { Link } from 'react-router-dom';

function SectionQuienesSomos() {
  return (
    <div className='container-quienes-somos'>
      <div className='wrapper-quienes-somos'>
        <h2 className='title-quienes-somos'>¿Quiénes somos?</h2>
        <div className='section-quienes-somos'>
          <div className='card-quienes-somos'>
            <img className='img-quienes-somos' src={imgUno} alt='' />
            <p className='text-quienes-somos'>
            <Link to="https://www.linkedin.com/in/esteban-gimenez-230b0652/" target='_blank' style={{textDecoration:"none", color: "#000"}}> Esteban Gimenez </Link><br />{' '}
              <span className='text-decoration-quienes-somos'>CO-FOUNDER de egnd</span>
            </p>
          </div>
          <div className='card-quienes-somos'>
            <img className='img-quienes-somos' src={imgDos} alt='' />
            <p className='text-quienes-somos'>
            <Link to="https://www.linkedin.com/in/nicolas-danilin-b19623b9/" target='_blank' style={{textDecoration:"none", color: "#000"}}> Nicolas Danilin</Link> <br />{' '}
              <span className='text-decoration-quienes-somos'>CO-FOUNDER de egnd</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionQuienesSomos;
