import React from 'react';
import Card from '../components/Card';
import { servicios } from '../data/infoText';
import HeaderConsultora from '../components/HeaderConsultora';

function Consultora() {
  return (
    <div className='container-consultora'>
      <HeaderConsultora />
    </div>
  );
}

export default Consultora;
