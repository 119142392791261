import React from 'react';
import HeaderPlaforma from '../components/HeaderPlaforma';
import AgendaReunion from '../components/AgendaReunion';

function Plataforma() {
  return (
    <div>
      <HeaderPlaforma />
      <AgendaReunion />
    </div>
  );
}

export default Plataforma;
