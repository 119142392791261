import React from 'react';
import imgUno from '../assets/images/Imagen_2.png';
import imgDos from '../assets/images/Imagen_3.png';

function SectionAlianzas() {
  return (
    <div className='container-alianzas'>
      <div className='wrapper-alianzas'>
        <div className='section-alianzas-text'>
          <h2 className='title-alianzas'>Alianzas</h2>
          <p className='text-alianzas'>
            Desde 2021 venimos acompañamos a la aceleradora{' '}
            <span className='text-decoration'>Mayma</span> y el{' '}
            <span className='text-decoration'>Banco Galicia</span> en el programa{' '}
            <span className='text-decoration'>“Escalar el impacto”</span>, para potenciar aquellas
            Startups de triple impacto que busquen financiarse con capital de riesgo.
          </p>
        </div>
        <div className='section-alianzas-img'>
          <img className='img-alianzas' src={imgUno} alt='' />
          <img className='img-alianzas' src={imgDos} alt='' />
        </div>
      </div>
    </div>
  );
}

export default SectionAlianzas;
