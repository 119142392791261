/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Link } from 'react-router-dom';
import linkedin from '../assets/images/Logo_linkedln.png';
import imgFooter from '../assets/images/Web/footer-plataforma.png';

function FooterPlataforma() {
  return (
    <>
      <img className='img-fondo-footer-plataforma' src={imgFooter} alt='' />
      <div className='container-footer-plataforma'>
        <div className='wrapper-footer-plataforma'>
          <div className='section-gris'>
            <div className='content-info-footer'>
              <span className='logo-footer'>egnd</span>
              <span className='text-info-footer'>
                info@egndfinance.com <br /> (011) 5526 3152
              </span>
            </div>
            <div className='content-menu-footer'>
              <span className='text-menu-footer'>Menú</span>
              <div className='content-nav-footer'>
                <Link to={`/`} className='link-footer'>
                  Inicio
                </Link>
                <Link to={`/consultora`} className='link-footer'>
                  Consultorías
                </Link>
                <Link to={`/programas`} className='link-footer'>
                  Programas
                </Link>
                <Link to={`/plataforma`} className='link-footer'>
                  Plataforma
                </Link>
              </div>
            </div>
            <div className='content-sociales-footer'>
              <Link target='_blank' to={`https://www.linkedin.com/company/egnd/`}>
                <img className='img-sociales' src={linkedin} alt='' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='cont-yp'>
        <p className='text-yp'>
          Desarrollado por{' '}
          <a className='link-yp' target='_blank' href='https://yellowpatito.com/inicio'>
            {' '}
            Yellow Patito
          </a>{' '}
          - 2023
        </p>
      </div>
    </>
  );
}

export default FooterPlataforma;
