import React from 'react';
import HeaderProgramas from '../components/HeaderProgramas';
import Card from '../components/Card';
import { contenidoPrograma } from '../data/infoText';
import Citacion from '../components/Citacion';

function Programas() {
  return (
    <div className='container-programas'>
      <HeaderProgramas />
      <div className='wrapper-cards'>
        <h2 className='title-card-programas'>Contenido del programa</h2>
        {contenidoPrograma.map((card, index) => (
          <Card key={index} tituloDos={card.tituloDos} items={card.items} imagen={card.imagen} />
        ))}
      </div>
      <Citacion />
    </div>
  );
}

export default Programas;
