/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import TestiMonialsDetails from '../TestiMonialsDetails/TestiMonialsDetails'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './TestiMonials.css'
import { DATA_CAROUSEL, DATA_LOGOS } from '../../constants/Carousel';
import { useMedia } from '../../utils/hooks/useMedia';
import bgDesktop from '../../assets/images/Web/Fondo_MitadHome.png';
import bgMobile from '../../assets/images/Web/Fondo_MitadHome.png';

const TestiMonials = () => {
    const media = useMedia();
  
    const testiMonials = DATA_CAROUSEL;
    const dataLogos = DATA_LOGOS;
    //Owl Carousel Settings
    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8000,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 2
            },
            1200: {
                items: 3
            }
        }
    };
    
    return (
        <section id="testimonial" className="testimonials" style={media === "mobile" ? {backgroundImage:`url(${bgMobile})`, paddingTop:"60x"} : {
            backgroundImage: `url(${bgDesktop})`,
          }}>
            <h3 className="sectionTitle">¿Qué opinan los founders?</h3>
        
            <div className="">
                <OwlCarousel id="customer-testimonoals" className="owl-carousel owl-theme" {...options}>
                    {
                        testiMonials && 
                            testiMonials.map((testiMonialDetail, i) => {
                                return (
                                    <TestiMonialsDetails key={i} testiMonialDetail={testiMonialDetail}  />

                                )
                            })
                    }
                </OwlCarousel>
            </div>

            <div className='slider'>
                <div className='slide-track'>
                    {
                        dataLogos.map((imgUrl, i)=> {
                            return (
                            <div className='slide' key={i}>
                                <img src={imgUrl}/>
                            </div>
                            )
                        })
                    }
                    

                </div>
            </div>
        </section>
    );
};

export default TestiMonials;