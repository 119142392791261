import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../assets/images/Logo.png';

function NavBar() {
  const [menuAbierto, setMenuAbierto] = useState(false);
  const [pantalla, setPantalla] = useState(false);
  const toggleMenu = () => {
    setMenuAbierto(!menuAbierto);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 576) {
        setPantalla(true);
      } else {
        setPantalla(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='container-nav'>
      <div className='wrapper-nav'>
        <div className='container-logo'>
          <Link to={`/`}>
            <img id='logo' src={logo} alt='' />
          </Link>
        </div>
        {pantalla && (
          <div className='container-icons-menu'>
            {menuAbierto ? (
              <i className='ri-close-line icon-menu' onClick={toggleMenu}></i>
            ) : (
              <i className='ri-menu-line icon-menu' onClick={toggleMenu}></i>
            )}
          </div>
        )}

        {pantalla ? (
          menuAbierto && (
            <ul className='nav'>
              <NavLink className='item-nav' to={`/`}  onClick={toggleMenu}>
                <li className='list-item'>Inicio</li>
              </NavLink>
              <NavLink className='item-nav' to={`/consultora`}  onClick={toggleMenu}>
                <li className='list-item'>Consultora</li>
              </NavLink>
              <NavLink className='item-nav' to={`/programas`} onClick={toggleMenu}>
                <li className='list-item'>Programas</li>
              </NavLink>
              <NavLink className='item-nav' to={`/plataforma`}  onClick={toggleMenu}>
                <li className='list-item'>Plataforma</li>
              </NavLink>
            </ul>
          )
        ) : (
          <ul className='nav'>
            <NavLink className='item-nav' to={`/`}>
              <li className='list-item'>Inicio</li>
            </NavLink>
            <NavLink className='item-nav' to={`/consultora`}>
              <li className='list-item'>Consultora</li>
            </NavLink>
            <NavLink className='item-nav' to={`/programas`}>
              <li className='list-item'>Programas</li>
            </NavLink>
            <NavLink className='item-nav' to={`/plataforma`}>
              <li className='list-item'>Plataforma</li>
            </NavLink>
          </ul>
        )}
      </div>
    </div>
  );
}

export default NavBar;
