import React from 'react';
import { Link } from 'react-router-dom';
import img from '../assets/images/Ilustracion_5.png';

function AgendaReunion() {
  return (
    <div className='container-reunion'>
      <div className='wrapper-reunion'>
        <div className='seccion-img-agenda'>
          <img src={img} alt='' className='img-agenda' />
        </div>
        <div className='seccion-text-agenda'>
          <h2 className='title-agenda'>¡Agenda una reunión!</h2>
          <p className='text-agenda'>
            Consulta el calendario para encontrar los horarios disponibles y así agendar una breve
            reunión de 15 minutos.{' '}
          </p>
          <Link target='_blank' to={`https://calendly.com/esteban_egnd/15min?month=2023-09`}>
            <button className='btn-agenda-reunion'>¡Hablemos!</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AgendaReunion;
