import imgUno from '../assets/images/image 19.png';
import comillasIzq from '../assets/images/Comillas_1.png';
import comillasDer from '../assets/images/Comillas_2.png';
import { useEffect, useState } from 'react';

function Citacion() {
  const [pantalla, setPantalla] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 991) {
        setPantalla(true);
      } else {
        setPantalla(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='container-citacion'>
      {pantalla ? (
        <div className='wrapper-citacion'>
          <div className='seccion-citacion'>
            <p className='text-citacion'>
              El objetivo principal es hacer un{' '}
              <span className='text-decoration-citacion'>Business Plan robusto</span> utilizando
              información financiera de calidad para que puedas{' '}
              <span className='text-decoration-citacion'>interpretar y gestionar tu Startup</span> o
              para cuantificar tu propuesta de valor.
            </p>

            <img src={comillasIzq} alt='' className='img-comillas-izq' />
            <img src={comillasDer} alt='' className='img-comillas-der' />
          </div>
          <div className='seccion-img-citacion'>
            <img className='img-citacion' src={imgUno} alt='' />
            <div className='content-name-cargo'>
              <span className='name-citacion'>Claudia Mercado</span>
              <span className='name-cargo-citacion'>Principal Associate</span>
            </div>
          </div>
        </div>
      ) : (
        <div className='wrapper-citacion'>
          <div className='seccion-citacion'>
            <p className='text-citacion'>
              El objetivo principal es hacer un{' '}
              <span className='text-decoration-citacion'>Business Plan robusto</span> utilizando
              información financiera de calidad para que puedas{' '}
              <span className='text-decoration-citacion'>interpretar y gestionar tu Startup</span> o
              para cuantificar tu propuesta de valor.
            </p>

            <div className='content-name-cargo'>
              <span className='name-citacion'>Claudia Mercado</span>
              <span className='name-cargo-citacion'>Principal Associate</span>
            </div>
            <img src={comillasIzq} alt='' className='img-comillas-izq' />
            <img src={comillasDer} alt='' className='img-comillas-der' />
          </div>
          <div className='seccion-img-citacion'>
            <img className='img-citacion' src={imgUno} alt='' />
          </div>
        </div>
      )}
    </div>
  );
}

export default Citacion;
