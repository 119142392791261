import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import FooterPlataforma from '../components/FooterPlataforma';

function LayoutPublic() {
  const currentPath = useLocation().pathname;
  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    if (currentPath.includes('plataforma')) {
      setShowFooter(false);
    } else {
      setShowFooter(true);
    }
    window.scrollTo(0, 0);
  }, [currentPath]);

  return (
    <div>
      <NavBar />
      <Outlet />
      {showFooter ? <Footer /> : <FooterPlataforma />}
    </div>
  );
}

export default LayoutPublic;
