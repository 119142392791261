import React from 'react';
import notebook from '../assets/images/Imagen_1 (2).png';

function HeaderInicio() {
  return (
    <div className='container-header-inicio'>
      <div className='wrapper-header-inicio'>
        <div className='section-header-inicio-text'>
          <h1 className='main-title'>Partner en finanzas para Startups</h1>
          <p className='header-inicio-text'>
            Brindamos soluciones y herramientas en finanzas que potencian el crecimiento y la
            escalabilidad de tu compañia.
          </p>
        </div>
        <div className='section-header-inicio-img'>
          <img id='img-header-inicio' src={notebook} alt='' />
        </div>
      </div>
    </div>
  );
}

export default HeaderInicio;
