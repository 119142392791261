/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import notebook from '../assets/images/Ilustracion_5.png';
import linkedin from '../assets/images/Logo_linkedln.png';

function Footer() {
  const currentPath = useLocation().pathname;
  const footerBg = {
    backgroundColor: currentPath === '/inicio'  || currentPath === '/'? '#F3F4F6' : '#fff', 
  };
  return (
    <><div className='container-footer' style={footerBg}>
      <div className='wrapper-footer'>
        <div className='section-azul'>
          <div className='content-img-footer'>
            <img className='img-pc-footer' src={notebook} alt='' />
          </div>
          <div className='content-text-footer'>
            <h2 className='title-footer'>¡Agenda una reunión!</h2>
            <p className='text-footer'>
              Consulta el calendario para encontrar los horarios disponibles y así agendar una breve
              reunión de 15 minutos.
            </p>
            <button className='btn-footer'>
              <Link target='_blank' to={`https://calendly.com/esteban_egnd/15min?month=2023-09`}>
                ¡Hablemos!
              </Link>
            </button>
          </div>
        </div>
        <div className='section-gris'>
          <div className='content-info-footer'>
            <span className='logo-footer'>egnd</span>
            <span className='text-info-footer'>
              info@egndfinance.com <br /> (011) 5526 3152
            </span>
          </div>
          <div className='content-menu-footer'>
            <span className='text-menu-footer'>Menú</span>
            <div className='content-nav-footer'>
              <Link to={`/`} className='link-footer'>
                Inicio
              </Link>
              <Link to={`/consultora`} className='link-footer'>
                Consultorías
              </Link>
              <Link to={`/programas`} className='link-footer'>
                Programas
              </Link>
              <Link to={`/plataforma`} className='link-footer'>
                Plataforma
              </Link>
            </div>
          </div>
          <div className='content-sociales-footer'>
            <Link target='_blank' to={`https://www.linkedin.com/company/egnd/`}>
              <img className='img-sociales' src={linkedin} alt='' />
            </Link>
          </div>
        </div>
      </div>
    </div><div className="cont-yp">
        <p className='text-yp'>Desarrollado por <a className='link-yp' target='_blank' href='https://yellowpatito.com/inicio'> Yellow Patito</a> - 2023</p>
      </div></>
  );
}

export default Footer;
