import React from 'react';
import { Link, useRouteError } from 'react-router-dom';

function NotFound() {
  const error = useRouteError();
  return (
    <div>
      <h1>404</h1>
      <p>Page not found</p>
      <p>{error.statusText || error.message}</p>
      <Link to={`/`}>Volver al Inicio</Link>
    </div>
  );
}

export default NotFound;
