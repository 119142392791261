import React from 'react';
import imgUno from '../assets/images/Ilustracion_1.png';
import imgDos from '../assets/images/Ilustracion_2.png';
import imgTres from '../assets/images/Ilustracion_3.png';
import { Link } from 'react-router-dom';

function SectionQueHacemos() {
  return (
    <div className='container-queHacemos'>
      <div className='wrapper-queHacemos'>
        <h2 className='title-queHacemos'>¿Qué hacemos?</h2>
        <div className='section-queHacemos'>
          <Link to={`/consultora`}>
            <div className='card-queHacemos'>
              <img className='img-queHacemos' src={imgUno} alt='' />
              <p className='text-queHacemos'>Consultora</p>
            </div>
          </Link>
          <Link to={`/programas`}>
            <div className='card-queHacemos'>
              <img className='img-queHacemos' src={imgDos} alt='' />
              <p className='text-queHacemos'>Programas</p>
            </div>
          </Link>
          <Link to={`/plataforma`}>
            <div className='card-queHacemos'>
              <img className='img-queHacemos' src={imgTres} alt='' />
              <p className='text-queHacemos'>Plataforma Business Plan</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SectionQueHacemos;
