import React from 'react';
import Card from '../components/Card';
import { servicios } from '../data/infoText';
function HeaderConsultora() {
  return (
    <div className='container-header-consultora'>
      <div className='wrapper-header-consultora'>
        <div className='section-header-consultora-text'>
          <h1 className='main-title'>Nuestros servicios</h1>
          <p className='header-consultora-text'>
            En EGND, estamos comprometidos a{' '}
            <span className='text-decoration-consultora'>impulsar el éxito de tu startup</span> a
            través de una variedad de soluciones especializadas, diseñadas para{' '}
            <span className='text-decoration-consultora'>
              optimizar tus operaciones y maximizar tu potencial de crecimiento
            </span>
            . Nuestras propuestas incluyen:
          </p>
        </div>
      </div>
      <div className='wrapper-cards'>
        {servicios.map((card, index) => (
          <Card
            key={index}
            tituloUno={card.tituloUno}
            subtitulo={card.subTitulo}
            items={card.items}
            imagen={card.imagen}
          />
        ))}
      </div>
    </div>
  );
}

export default HeaderConsultora;
