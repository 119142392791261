
import MeCubro from '../assets/images/Logo_1.png';
import logo2 from '../assets/images/Logo_2.png';
import Eternal from '../assets/images/Logo_3.png';
import logo4 from '../assets/images/Logo_4.png';
import logo5 from '../assets/images/Logo_5.png';
import Ensayo from '../assets/images/Logo_6.png';
import Eye from '../assets/images/Logo_7.png';
import logo8 from '../assets/images/Logo_8.png';
import logo9 from '../assets/images/Logo_9.png';
import logo10 from '../assets/images/Logo_10.png';
import logo11 from '../assets/images/Logo_11.png';
import logo12 from '../assets/images/Logo_12.png';
import logo13 from '../assets/images/Logo_13.png';
import logo14 from '../assets/images/Logo_14.png';
import logo15 from '../assets/images/Logo_15.png';
import Logo16 from '../assets/images/Logo_16.png';
import Napsix from '../assets/images/Logo_17.png';
import Logo18 from '../assets/images/Logo_18.png';
import Logo19 from '../assets/images/Logo_19.png';
import Logo20 from '../assets/images/Logo_20.png';
import Logo21 from '../assets/images/Logo_21.png';
import Logo22 from '../assets/images/Logo_22.png';
import Logo23 from '../assets/images/Logo_23.png';
import Logo24 from '../assets/images/Logo_24.png';
import Logo25 from '../assets/images/Logo_25.png';


export const DATA_CAROUSEL = [
    // {
    //     name: 'Germán Gimenez | Founder de Komuny & Napsix ',
    //     description: '"Para poder afrontar rondas de financiamiento es clave contar con un Business Plan sólido y gracias a egnd logramos alinear nuestro modelo de negocio a una proyección que sea viable económica y financieramente."',
    //     img: Napsix,
    //     img2:Logo16,
    // },
    {
        name: 'Georgina Sposetti | CEO & Founder de Un ensayo para mi',
        description: '"Con egnd logramos tener muy en claro nuestro modelo de negocio y por sobre todas las cosas entenderlo a partir de las finanzas que resulta muy útil para tomar decisiones de corto y largo plazo."',
        img: Ensayo
    },
    {
        name: 'Javier Ailbirt | CEO & Founder de THE EYE',
        description: '”El Programa permite a los founders contar con un business plan sumamente sólido en términos de viabilidad financiera, necesarios para afrontar una ronda de inversión.”',
        img: Eye
    },
    {
        name: 'Rikki Bueno | CEO & Founder de Mecubro',
        description: '“Para poder escalar regionalmente necesitábamos un modelo financiero consistente que soporte la organización , EGND implementó procesos financieros que nos permitieron entender con mayor claridad la operación y los financials de Coinsenda.”',
        img: MeCubro
    },
    {
        name: 'Horacio Acerbo | CEO & Founder de ETERNAL',
        description: '“egnd nos permitió cuantificar nuestra idea de negocio en un financial plan de forma sólida y clara.Generando sinergia entre todas las áreas de eternal para poder tener todos los financials alineados a la estrategia de negocio.”',
        img: Eternal
    },
]

export const DATA_LOGOS =  [
    logo10, logo11, logo12, logo13, logo14, logo15, Logo16, logo9, logo8, logo4, logo5,logo2, Eternal, Napsix, MeCubro, Eye, Ensayo, Logo18, Logo19, Logo20, Logo21, Logo22, Logo23, Logo24, Logo25
]