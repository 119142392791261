import React from 'react';
import mapa from '../assets/images/Ilustracion_4.png';

function SectionMapa() {
  return (
    <div className='container-mapa'>
      <div className='wrapper-mapa'>
        <h2 className='title-mapa'>Acompañamos a más de 50 startups</h2>
        <img className='img-mapa' src={mapa} alt='' />
      </div>
    </div>
  );
}

export default SectionMapa;
