export const servicios = [
  {
    tituloUno: 'CaaS',
    subTitulo: '(controller as a service)',
    tituloDos: null,
    imagen: require('../assets/images/Ilustracion_6.png'),
    items: [
      '✔ Elaboración de informes de gestión (P&L y su análisis)',
      '✔ Construcción de indicadores',
      '✔ Gestión de costos',
      '✔ Cashflow',
      '✔ Planificación Financiera (Budget,forecast)',
      '✔ Seguimiento mensual de la compañía',
      '✔ Generación de informes financieros y reportes contables para Startups,   Stakeholders, Board Members e Inversores',
    ],
  },
  {
    tituloUno: 'Administración y procesos',
    subTitulo: null,
    tituloDos: null,
    imagen: require('../assets/images/Ilustracion_7.png'),
    items: [
      '✔ Ordenamiento de la información para optimizar recursos',
      '✔ Mejora o creación de procesos para el correcto flujo de la información',
      '✔ Implementacion de ERP',
    ],
  },
  {
    tituloUno: 'Proceso de Fundraising',
    subTitulo: null,
    tituloDos: null,
    imagen: require('../assets/images/Ilustracion_8.png'),
    items: [
      '✔ Determinación de modelo de negocio y revenue model',
      '✔ Financial Plan con proyecciones a 5 años y todas las métricas que permitan explicar el crecimiento de la compañía',
      '✔ Units economics, KPI’s, Valuation',
      '✔ Armado de materiales para afrontar un proceso de levantamiento de capital (One Pager, Pitch Deck, Business Plan, Financial plan)',
      '✔ Defensa de modelo durante el proceso',
    ],
  },
  {
    tituloUno: 'Valuaciones',
    subTitulo: null,
    tituloDos: null,
    imagen: require('../assets/images/Ilustracion_9.png'),
    items: [
      '✔ Análisis de estados financieros, identificando tendencias y proyecciones',
      '✔ Análisis de industria y competencia para valorar su posición en el mercado',
      '✔ Combinación de métodos de valuación adecuados en función del negocio',
      '✔ Informe resaltando los factores clave del valor estimado',
      '✔ Meet final de presentación del informe de valuación',
    ],
  },
];

export const contenidoPrograma = [
  {
    tituloUno: null,
    subTitulo: null,
    tituloDos: 'Módulo 1: Control de Gestión',
    imagen: require('../assets/images/Ilustracion_13.png'),
    items: [
      '✔ Análisis de Organigrama',
      '✔ Análisis de Flujo de información',
      '✔ Elaboración de P&L histórico, interpretarlo y controlarlo',
      '✔ Gestión ventas, costos variables, gastos de estructura y cashflow',
    ],
  },
  {
    tituloUno: null,
    subTitulo: null,
    tituloDos: 'Módulo 2: Propuesta de valor y Revenue Model',
    imagen: require('../assets/images/Ilustracion_14.png'),
    items: [
      '✔ Validación de propuesta de valor',
      '✔ Elección de modelo de ingresos',
      '✔ Mercado objetivo y tracción',
    ],
  },
  {
    tituloUno: null,
    subTitulo: null,
    tituloDos: 'Módulo 3: Proyecciones y modelo financiero',
    imagen: require('../assets/images/Ilustracion_15.png'),
    items: [
      '✔ Propuesta de valor personalizada',
      '✔ Analizar la viabilidad entendiendo costos variables y gastos de estructura',
      '✔ Valuación bajo el método más adecuado según su estadio y mercado',
      '✔ Proyectar la Startup a 5 años',
    ],
  },
  {
    tituloUno: null,
    subTitulo: null,
    tituloDos: 'Módulo 4: Materiales de financiamiento y gestión',
    imagen: require('../assets/images/Ilustracion_16.png'),
    items: ['✔ One Pager', '✔ Pitch Deck', '✔ Business Plan', '✔ Financials'],
  },
];
