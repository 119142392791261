import React from 'react';
import imgUno from '../assets/images/Ilustracion_10.png';
import imgDos from '../assets/images/Ilustracion_11.png';
import imgTres from '../assets/images/Ilustracion_12.png';

function HeaderProgramas() {
  return (
    <div className='container-header-programas'>
      <div className='wrapper-header-programas'>
        <div className='section-header-programas-text'>
          <h1 className='main-title'>Nuestros programas</h1>
          <p className='header-programas-text'>
            Desarrollamos un programa de aceleración financiera diseñado para proporcionarte una
            <span className='text-decoration-consultora'>
              visión nítida de tu modelo de negocio
            </span>{' '}
            en tan solo <span className='text-decoration-consultora'>4 meses</span>. A través de
            este programa,
            <span className='text-decoration-consultora'>
              validaremos y definiremos métricas y proyecciones esenciales
            </span>
            , sentando las bases para la creación de un{' '}
            <span className='text-decoration-consultora'>Business Plan</span> de tu Startup. Esta
            hoja de ruta te brindará la flexibilidad de asegurar financiamiento externo o guiar un
            crecimiento orgánico sostenible.
          </p>
        </div>
        <div className='section-header-programas-img'>
          <div className='wrapper-img-card-programas'>
            <div className='cont-explicacion'>
              <h4 className='title-img-programas'>4 módulos</h4>
              <span className='caracteristica-img-programas'>
                Vas a aprender con información de tu startup
              </span>
            </div>
            <div className='container-img-descripcion-programas'>
              <img src={imgUno} alt='' className='img-descripcion-programas' />
            </div>
          </div>

          <div className='wrapper-img-card-programas'>
            <div className='cont-explicacion'>
              <h4 className='title-img-programas'>16 reuniones</h4>
              <span className='caracteristica-img-programas'>
                1 a 1 con especialistas en finanzas
              </span>
            </div>
            <div className='container-img-descripcion-programas'>
              <img src={imgDos} alt='' className='img-descripcion-programas' />
            </div>
          </div>

          <div className='wrapper-img-card-programas'>
            <div className='cont-explicacion'>
              <h4 className='title-img-programas'>Entregables</h4>
              <span className='caracteristica-img-programas'>
                Diagnóstico real + Business Plan + One Pager + Pitch Deck
              </span>
            </div>
            <div className='container-img-descripcion-programas'>
              <img src={imgTres} alt='' className='img-descripcion-programas' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderProgramas;
