import React from 'react';
import HeaderInicio from '../components/HeaderInicio';
import SectionQueHacemos from '../components/SectionQueHacemos';
import SectionMapa from '../components/SectionMapa';
import SectionAlianzas from '../components/SectionAlianzas';
import SectionQuienesSomos from '../components/SectionQuienesSomos';
import TestiMonials from '../components/TestiMonials/TestiMonials';

function Inicio() {
  return (
    <div style={{backgroundColor:"#F3F4F6"}}>
      <HeaderInicio />
      <SectionQueHacemos />
      <SectionMapa />
      <TestiMonials/>
      <SectionAlianzas />
      <SectionQuienesSomos />
    </div>
  );
}

export default Inicio;
