import React from 'react';
import { useLocation } from 'react-router';

function Card({ tituloUno, subtitulo, tituloDos, imagen, items }) {
  const currentPath = useLocation().pathname;

  return (
    <div className='container-card'>
      <div className='content-img-card'>
        {tituloUno && (
          <h3 className='titulo-uno-card'>
            {tituloUno}
            <br />
            {subtitulo && <span className='subtitulo-card'>{subtitulo}</span>}
          </h3>
        )}

        <img src={imagen} className={`${currentPath !== "/programas"  ? "imgCardCon" : "imgCardPro"}'`} alt='' />
      </div>

      <div className='content-text-card'>
        {tituloDos && <h3 className='titulo-dos-card'>{tituloDos}</h3>}
        <ul className='wrapper-items-card'>
          {items &&
            items.map((item, index) => (
              <li className='item-card' key={index}>
                {item}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default Card;
