import React from 'react';
import miImagen from '../assets/images/auth-side-bg.jpg'; 

function Construccion() {
    const linkedinURL = 'https://www.linkedin.com/company/egnd/'; // Reemplaza esta URL con la dirección de tu página de LinkedIn

    const handleLinkedInClick = () => {
      window.open(linkedinURL, '_blank');
    };
    const divStyle = {
        backgroundImage: `url(${miImagen})`,
        backgroundSize: 'cover', 
      };
    return (
        <div className='containerConstruccion'>
            <div className='left' style={divStyle}>
                <img className="imgLogo" src='https://i.imgur.com/xYne5uA.png'  alt="logo" />

                <p>Nuestro sitio se encuentra en desarrollo, visite nuestro Linkedin para estar al tanto de nuenstras novedades.</p>
            </div>
            <div className='right'>
                <img className="imgConstruccion" src='https://i.imgur.com/5I1aNJx.jpg' alt="description" />
                <button onClick={handleLinkedInClick} className="btnConstruccion"> Ir a nuestro LinkedIn</button>
            </div>
        </div>
    )
}

export default Construccion;
