import { Link } from 'react-router-dom';
import imgPc from '../assets/images/Imagen_6 (2).png';
// import a from '../assets/images/Mobile/Fondo_Programas.png';

function HeaderPlaforma() {
  return (
    <div className='container-header-plataforma'>
      {/* <img id='ignacio' src={a} alt='' /> */}
      <div className='wrapper-header-plataforma'>
        <div className='section-header-plataforma-text'>
          <h1 className='main-title-plataforma'>Plataforma</h1>
          <p className='header-plataforma-text'>
            Desarrollamos una plataforma de Business Plan as a Service, donde las Startups podrán
            realizar su{' '}
            <span className='text-decoration-plataforma'>modelo de negocio completo</span>{' '}
            gestionando las proyecciones de la compañía y compartiendo la información con fondos de
            inversión o entidades de financiamiento.
          </p>
          <div className='container-btn-plataforma'>
          <span className='proximamente'>Próximamente</span>

            {/* <Link target='_blank' to={`https://app.egndfinance.com/crear-cuenta`}>
              <button className='btn-crear-cuenta'>Crear cuenta</button>
            </Link>
            <Link target='_blank' to={`https://app.egndfinance.com/iniciar-sesion`}>
              <button className='btn-ingresar'>Ingresar</button>
            </Link> */}
              {/* <button className='btn-ingresar' disabled>Próximamente</button> */}
          </div>
        </div>
        <div className='section-header-plataforma-img'>
          <img id='img-header-plataforma' src={imgPc} alt='' />
        </div>
      </div>
    </div>
  );
}

export default HeaderPlaforma;
