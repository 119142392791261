/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

const TestiMonialsDetails = ({testiMonialDetail}) => {
    const {name, description, img, img2} = testiMonialDetail;
    return (
        <div class="item">
            <div class="shadow-effect cardTestimonial">
                {img2 ? (
                    <div className='contImg'>
                        <img class="img-circle-doble" src={img} />
                        <img class="img-circle-doble" src={img2} />
                    </div>
                )
                 :
                (
                    <img class="img-circle" src={img} />
                )}
                <h5 className='titleT'>{name}</h5>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default TestiMonialsDetails;